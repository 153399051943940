// How it works
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import './css/LP_Section3.css';
// Artworks image
import ArtPNG from '../../optimized_media/obj_art.png';
import ArtAVIF from '../../optimized_media/obj_art.avif';
import ArtWEBP from '../../optimized_media/obj_art.webp';
// Luxe image
import ObjLuxePNG from '../../optimized_media/obj_luxe.png';
import ObjLuxeAVIF from '../../optimized_media/obj_luxe.avif';
import ObjLuxeWEBP from '../../optimized_media/obj_luxe.webp';
// Collection image
import ObjCollectionPNG from '../../optimized_media/obj_collection.png';
import ObjCollectionAVIF from '../../optimized_media/obj_collection.avif';
import ObjCollectionWEBP from '../../optimized_media/obj_collection.webp';

// Immo image
import ImmoPNG from '../../optimized_media/obj_immo.png';

const Section3 = ({ handleOpenPopup }) => {
  return (
    <section className="section3" id="section3">
      <Row>
        <Col xs={1} sm={1} md={0} lg={0} xl={10}/>
        <Col xs={22} sm={22} md={24} lg={24} xl={24}>
          <h1>Un univers d'investissement sans limite</h1>
        </Col>
      </Row>
      <Row className="sous_section3">
        <Col xs={10} sm={12} md={12} lg={12} xl={5}>
          <div className="section3_liste">
            <h2>Objets de <br/> Collection</h2>
            <img className="produit" src={ObjCollectionPNG} alt='Objets de collection'/>
            <p>Possédez des trésors culturels avec d'autres passionnés</p>
          </div>
        </Col>
        
        <Col xs={10} sm={12} md={12} lg={12} xl={5}>
          <div className="section3_liste">
            <h2>Oeuvres <br/> d'art</h2>
            <img className="produit" src={ArtPNG} alt="Oeuvres d'art" style={{maxWidth:'90%'}}/>
            <p>Investissez en mécène et decouvrez des œuvres uniques</p>
          </div>
        </Col>
        <Col xs={10} sm={12} md={12} lg={12} xl={5}>
          <div className="section3_liste">
            <h2>Pièces <br/>Iconiques</h2>
            <img className="produit" src={ObjLuxePNG} alt="Pièces Iconiques"/>
          <p>Diversifiez vos actifs avec des pièces de luxe à forte valorisation.</p>
          </div>
        </Col>
        <Col xs={10} sm={12} md={12} lg={12} xl={5}>
          <div className="section3_liste">
            <h2>Biens d'exception</h2>
            <img className="produit" src={ImmoPNG} alt='Start-ups'/>
            <p>
              Devenez un châtelain des temps modernes
              <br/>(dès 2026)
            </p>
          </div>
        </Col>
      </Row>
      <Row>
      <Col xs={5} sm={8} md={8} lg={8} xl={10}/>
        <Col xs={14} sm={8} md={8} lg={8} xl={4}>
          <li className="button_openPopUp" onClick={handleOpenPopup}>Investir maintenant !</li>
        </Col>
    </Row>
    </section>
  );
}


// const Section3 = () => {
//   return (
//     <section className="title_section3">
//     <h1>Découvrez un univers d'investissement sans limite</h1>
//         <section className="section3">
//             <li className="section3_liste">
//                 <h2>Oeuvres d'art</h2>
//                 <img className="produit" src={Art}/>
//                 <p>Devenez un mécène moderne en détenant des parts d'oeuvres d'art</p>
//             </li>

//             <li className="section3_liste">
//                 <h2>Collections</h2>
//                 <img className="produit" src={Collection}/>
//                 <p>Possédez des trésors culturels avec d'autres passionnés</p>
//             </li>

//             <li className="section3_liste">
//                 <h2>Start-Ups</h2>
//                 <img className="produit" src={Startup}/>
//                 <p>Devenez business angel en investissant dans des sociétés innovantes non-cotées</p>
//             </li>

//             <li className="section3_liste">
//                 <h2>Private Funds</h2>
//                 <img className="produit" src={Fund}/>
//                 <p>Déverrouillez des opportunités avec d'autres opportunistes</p>
//             </li>
//         </section>
//     </section>
    
//   );
// }

// export default Section3;

export default Section3;
