import React, { useState, useEffect, useRef, useContext } from 'react';
import { Carousel, Row, Col, Spin } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, XOutlined } from '@ant-design/icons';

import { AuthContext } from '../AuthContext/AuthContext';
import './AuthPopup.css';

import Collection from '../../optimized_media/obj_collection_w_back.avif';
import Luxe from '../../optimized_media/obj_luxe_w_back.avif';
import Art from '../../optimized_media/obj_art_w_back.avif';
import Immo from '../../optimized_media/obj_immo_w_back.png';


const AuthPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // État pour le statut de chargement
  const [isLoginMode, setIsLoginMode] = useState(true);
  const popupRef = useRef();
  const { login } = useContext(AuthContext); // Utilisez le contexte
  const isSmallScreen = window.matchMedia('(max-width: 576px)').matches;

  //------------------------------------------------------------------------------------------------------------//
  /* Close when click outside of popup */
  //------------------------------------------------------------------------------------------------------------//
  const handleClickOutside = (event) => {
    if (popupRef.current && popupRef.current.contains(event.target.querySelector('.popup-content'))) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClose]);
  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//


  //------------------------------------------------------------------------------------------------------------//
  /* Sign In/Sign Up functions */
  //------------------------------------------------------------------------------------------------------------//

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = () => {
    
    if (!email.trim() || !isValidEmail(email)) {
      setMessage(<span className="email-error2">Veuillez entrer une adresse e-mail valide.</span>);
      return;
    }
    setLoading(true);  // Début du chargement
    fetch('https://stratup1.azurewebsites.net/register', { //https://stratup1.azurewebsites.net
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((response) => {
        return response.json()
        .then((data) =>({ data, response })); // Continuer seulement si la réponse n'est pas 409
      })
      .then((result) => {
        setLoading(false);  // Fin du chargement
        if (!result) return; // Si result est null, sortir
        const { data, response } = result;
          if (data.message) {
            setMessage(<span className="email-message2">{data.message}</span>);
          } else {
            setMessage(<span className="email-error2">Erreur lors de l'inscription.</span>);
          }
      })
      .catch((error) => {
        setLoading(false);  // Fin du chargement
        console.error('Erreur lors de l\'inscription:', error);
        setMessage(<span className="email-error2">Erreur lors de l'inscription.</span>);
      });
  };

  const handleSignIn = () => {
    if (!email.trim() || !isValidEmail(email)) {
      setMessage(<span className="email-error2">Veuillez entrer une adresse e-mail valide.</span>);
      return;
    }
    setLoading(true);  // Début du chargement
    fetch('https://stratup1.azurewebsites.net/login', { // 'https://stratup1.azurewebsites.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then(async (response) => {
        const data = await response.json();
        setLoading(false);  // Fin du chargement
        if (response.ok) {
          if (data.access_token) {
            setMessage(<span className="email-message2">Connexion réussie ! Vous allez être redirigés. </span>);
            setTimeout(() => {
              login(data.access_token);
              window.location.href = "/";
            }, 1500);
          } 
          else {
            setMessage(<span className="email-message2">{data.message}</span>);
          }
        } else if (response.status === 401) {
          setMessage(
            <span className="email-error2">
              {data.error}
              <br />
              <button className="button_signUp" id="submitBtn" onClick={handleResetRequest}>
                Réinitialiser votre mot de passe ?
              </button>
            </span>
          );
        } else {
          setMessage(<span className="email-error2">Erreur lors de la connexion.</span>);
        }
      })
      .catch((error) => {
        setLoading(false);  // Fin du chargement
        console.error('Erreur lors de la connexion:', error);
        setMessage(<span className="email-error2">Erreur lors de la connexion.</span>);
      });
  };
  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//



  //------------------------------------------------------------------------------------------------------------//
  /* Reset password request */
  //------------------------------------------------------------------------------------------------------------//

  const handleResetRequest = () => {
    setLoading(true);  // Début du chargement
    fetch('https://stratup1.azurewebsites.net/reset_password_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);  // Fin du chargement
        if (data.message) {
          setMessage(<span className="email-message2">{data.message}</span>);
        } else {
          setMessage(<span className="email-error2">{data.error}</span>);
        }
      })
      .catch((error) => {
        setLoading(false);  // Fin du chargement
        console.error('Erreur lors de la demande de réinitialisation:', error);
        setMessage(<span className="email-error2">Erreur lors de la demande de réinitialisation.</span>);
      });
  };

  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//


  return (
    <Row className="auth-popup" ref={popupRef}>
      <Col xs={4} sm={4} md={2} lg={5} xl={5} />
      <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} />
        <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
          <h1>{isLoginMode ? 'Connexion' : 'Inscription'}</h1>
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button className="button_signUp" id="submitBtn" onClick={isLoginMode ? handleSignIn : handleSignUp}>
            {isLoginMode ? 'Se connecter' : "S'inscrire"}
          </button>
          <p>{loading ? <Spin /> : message}</p>
          <p>
            {isLoginMode ? "Pas encore de compte ? " : "Déjà inscrit ? "}
            <span onClick={() => setIsLoginMode(!isLoginMode)} style={{ cursor: 'pointer', color: 'blue' }}>
              {isLoginMode ? "S'inscrire" : 'Se connecter'}
            </span>
          </p>
          <h2>Suivez-nous !</h2>
          <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <LinkedinOutlined />
          </a>
          <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <FacebookOutlined />
          </a>
          <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <InstagramOutlined />
          </a>
          <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <XOutlined />
          </a>
        </Col>
        {isSmallScreen && (<div className="close-button" onClick={onClose}>X</div>)}
        <Col span={1}/>
        <Col/>
        <Col xs={0} sm={0} md={13} lg={10} xl={10}>
          <Carousel autoplay className="image-slider">
            <img className="image" src={Collection} alt="Collection" />
            <img className="image" src={Luxe} alt="Luxe" />
            <img className="image" src={Art} alt="Art" />
            <img className="image" src={Immo} alt="Immo" />
          </Carousel>
          <div className="close-button" onClick={onClose}>
            X
          </div>
        </Col>
      </Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5} />
    </Row>
  );
};

export default AuthPopup;
