// How it works
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import './css/LP_Section1.css'

//PHONE IMG 
import PhonePNG from '../../optimized_media/phone.png';
import PhoneAVIF from'../../optimized_media/phone.avif';
import PhoneWEBP from'../../optimized_media/phone.webp';



const Section1 = ({ handleOpenPopup }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia('(max-width:850px)').matches || window.matchMedia("(orientation: portrait)").matches);
    };

    // Initial check
    handleResize();

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <section id="section1">
    <Row className="section1">
      <Col span={2}/>
      <Col span={isSmallScreen ? 20 : 14}>
        <h1>Investissez dans l'inaccessible</h1>
        <p>
            Explorez un monde d'opportunités en obtenant des parts d'objets de collection et de luxe exclusifs,
            d'oeuvres d'art uniques et de startups innovantes en quelques clics, à partir de 10€.
            Rejoignez Stratup et sa vision d'un investissement accessible, simple et sécurisé grâce à la blockchain
            et participez à la création d'un avenir financier sans contraintes. 
        </p> 
        <h2>Investissez selon vos moyens et obtenez des parts en fonction de votre contribution !</h2>
        <li className="button_openPopUp" onClick={handleOpenPopup}>Investir maintenant !</li>
      </Col>
      <Col span={isSmallScreen ? 7 : 2}/>
      <Col span={isSmallScreen ? 10 : 5} style={{display: 'flex', alignItems: 'center'}}>
        <picture>
          <source srcSet={PhoneAVIF} type="image/avif" loading="lazy"/>
          <source srcSet={PhoneWEBP} type="image/webp" loading="lazy"/>
          <img src={PhonePNG} className="phone" alt='Phone App' loading="lazy"/>
        </picture>
      </Col>
    </Row>
    </section>
  );
}

export default Section1;
