import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Carousel, Row, Col,Spin, Result, Button } from 'antd';

// import './ResetPasswordPopUp.css';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, XOutlined } from '@ant-design/icons';

import Collection from '../../optimized_media/obj_collection_w_back.avif';
import Luxe from '../../optimized_media/obj_luxe_w_back.avif';
import Art from '../../optimized_media/obj_art_w_back.avif';
import Immo from '../../optimized_media/obj_immo_w_back.png';


const VerifyEmail = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(5); // État pour le compte à rebours
  const [returned, setReturned] = useState(<Spin tip="Vérification de l'email en cours..." />)
  const popupRef = useRef();
  const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 


  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setError('Token manquant.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`https://stratup1.azurewebsites.net/verify_email?token=${token}`);
        const data = await response.json();

        if (response.ok) {
          setVerified(true);
          setLoading(false);
          // Démarrer le compte à rebours de 5 secondes pour rediriger
          const timer = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(timer);
                navigate('/?openPopUp=true');
              }
              return prev - 1;
            });
          }, 1000);
        } else {
          setError(data.error || 'Erreur lors de la vérification de l\'email.');
          setLoading(false);
        }
      } catch (err) {
        setError('Erreur lors de la vérification de l\'email.');
        setLoading(false);
      }
    };

    verifyEmail();
  }, [location, navigate]);

  if (loading) {
    return (
      <Row className="resetPassword-popup" ref={popupRef}>
        <Col xs={4} sm={4} md={2} lg={5} xl={5} />
        <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
          <Col xs={2} sm={2} md={1} lg={1} xl={1} />
          <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
            <Spin tip="Vérification de l'email en cours..." />
            <h2>Suivez-nous !</h2>
            <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
              <LinkedinOutlined />
            </a>
            <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
              <FacebookOutlined />
            </a>
            <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
              <InstagramOutlined />
            </a>
            <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
              <XOutlined />
            </a>
          </Col>
          <Col span={1}>{isSmallScreen && <div className="close-button" onClick={onClose}>X</div>}</Col>
          <Col xs={0} sm={0} md={13} lg={10} xl={10}>
            <Carousel autoplay className="image-slider">
              <img className="image" src={Collection} alt="Collection" />
              <img className="image" src={Luxe} alt="Luxe" />
              <img className="image" src={Art} alt="Art" />
              <img className="image" src={Immo} alt="Immo" />
            </Carousel>
          </Col>
        </Col>
        <Col xs={4} sm={4} md={5} lg={5} xl={5} />
      </Row>
    )
  }
  if (error) {
    return (
      <Row className="resetPassword-popup" ref={popupRef}>
      <Col xs={4} sm={4} md={2} lg={5} xl={5} />
      <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} />
        <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
          <Result
            status="error"
            title="Échec de la vérification de l'email"
            subTitle={error}
            extra={<Button type="primary" href="/?openPopUp=true">Retour à l'accueil dans {countdown}s</Button>}
          />
          <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <LinkedinOutlined />
          </a>
          <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <FacebookOutlined />
          </a>
          <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <InstagramOutlined />
          </a>
          <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <XOutlined />
          </a>
        </Col>
        <Col span={1}>{isSmallScreen && <div className="close-button" onClick={onClose}>X</div>}</Col>
        <Col xs={0} sm={0} md={13} lg={10} xl={10}>
          <Carousel autoplay className="image-slider">
            <img className="image" src={Collection} alt="Collection" />
            <img className="image" src={Luxe} alt="Luxe" />
            <img className="image" src={Art} alt="Art" />
            <img className="image" src={Immo} alt="Immo" />
          </Carousel>
        </Col>
      </Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5} />
    </Row>
    );
  }
  
  return (
    <Row className="resetPassword-popup" ref={popupRef}>
    <Col xs={4} sm={4} md={2} lg={5} xl={5} />
    <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
      <Col xs={2} sm={2} md={1} lg={1} xl={1} />
      <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
        <Result
          status="success"
          title="Email vérifié avec succès!"
          subTitle="Votre adresse email a été vérifiée avec succès."
          extra={<Button type="primary" href="/?openPopUp=true">Se connecter ({countdown}s)</Button>}
        />
          <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <LinkedinOutlined />
          </a>
          <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <FacebookOutlined />
          </a>
          <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <InstagramOutlined />
          </a>
          <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <XOutlined />
          </a>
        </Col>
        <Col span={1}>{isSmallScreen && <div className="close-button" onClick={onClose}>X</div>}</Col>
        <Col xs={0} sm={0} md={13} lg={10} xl={10}>
          <Carousel autoplay className="image-slider">
            <img className="image" src={Collection} alt="Collection" />
            <img className="image" src={Luxe} alt="Luxe" />
            <img className="image" src={Art} alt="Art" />
            <img className="image" src={Immo} alt="Immo" />
          </Carousel>
        </Col>
      </Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5} />
    </Row>
  );
};

export default VerifyEmail;

